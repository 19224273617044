div {
    font-family: 'Montserrat', sans-serif;
}

.blurb {
    padding: 5px;
    color: white;
}

.artist-page {
    background-size: 100% 100%;
    width: 100vw;
    min-height: 100vh;
}

.line-div {
    height: 1px;
    margin-left: 0%;
    margin-top: 2%;
}

.single-artist-name {
    font-size: 2.2rem;
    color: white;
    padding-top: .5rem;
}


.single-artist-title {
    color: white;
    font-family: montserratThinItalic;
    font-size: 1.5rem;
    margin-bottom: 3%;
}

.artist-main-image {
    padding-top: 2%;
    display: flex;
    justify-content: center;
}

.artist-blurb {
    color: white;
    margin-top: 2%;
    padding-bottom: 2%;
}

.solo-artist-name {
    font-size: 2rem;
    color: white;
    padding-top: .5rem;
}

.artist-intro-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    font-style: italic;
    color: white;
    font-size: 1.5rem;
}

.artist-company-name {
    font-size: 1.2rem;
    color: white;
    font-style: italic;
    margin-bottom: 1%;
    font-family: montserratThin;
}

.social {
    margin-top: 1%;
    margin-bottom: 1%;
    color: white;
    text-decoration: none;
    font-family: montserratThinItalic;
}

.artist-name {
    font-size: 2.5rem;
    color: white;
    text-align: center;
    padding-top: 2rem;
}

.artist-company-link {
    display: flex;
    color: white;
    justify-content: center;
    padding-bottom: 2rem;
}

.artifact-list-container {
    padding: 1rem 0 1rem 1rem;
    color: white;
    font-size: 1.5rem;
}

.artifact-list {
    padding-top: 1rem;
    color: white;
}

.artifact-list-name {
    font-size: 1.2rem;
}

.artifact-list-description {
    font-size: 1rem;
    font-style: italic;
    padding-bottom: 1rem;
}

.artist-carousel-wrapper {
    padding-top: 2%;
}


a:link { text-decoration: none; }

a:visited { text-decoration: none; }

.blurb-container {
    overflow-y: scroll;
    max-height: 20%;
    width: 80%;
    display: flex;
    justify-content: center;
    border: 1px solid white;
}

/* MOBILE-S */
@media screen and (max-width: 320px){
    .artist-info-container{
        width: 90%;
    }

    .line-div {
        border-bottom: 3px solid white;
        width: 50%;
    }

    .artist-page {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1678994623/evan%20web%20photos/Artist_BG_2-100_rgzx2v.jpg");
    }

    .artist-main-image {
        width: 90%;
    }

    .social {
        word-break: break-all;
    }

    .info-container {
        padding: 1rem;
    }
}

/* MOBILE */
@media screen and (min-width: 321px) and (max-width: 539px){
    .line-div {
        border-bottom: 3px solid white;
        width: 50%;
    }

    .artist-wrapper {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1678994623/evan%20web%20photos/Artist_BG_2-100_rgzx2v.jpg");
        background-size: 100% 100%;
        min-height: 100vh;
    }

    .artist-info-container{
        width: 90%;
    }

    .artist-main-image {
        width: 90%;
    }

    .artist-blurb {
        margin-right: 10%;
    }

    .social {
        word-break: break-all;
    }

    .info-container {
        padding: 1rem;
    }
}
    
/* TABLET-S */
@media screen and (min-width: 540px) and (max-width: 557px){
    .artist-wrapper {
        background-size: 100% 100%;
        min-height: 100vh;
        width: 50%;
    }

    .line-div {
        border-bottom: 3px solid white;
        width: 50%;
    }

    .artist-info-container{
        width: 100%;
    }
    
    .artist-page {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1686930140/Desktop_Artist_BG-50_o6x1ba.jpg");
    }

    .artist-main-image {
        width: 100%;
    }

    .artist-blurb {
        padding-right: 15%;
    }

    .info-container {
        padding: 1rem;
    }

    .social {
        word-break: break-all;
    }
}

/* TABLET */
@media screen and (min-width: 558px) and (max-width: 768px){
    .artist-wrapper {
        width: 65%;
    }

    .line-div {
        border-bottom: 2px solid white;
        width: 65%;
    }

    .artist-info-container{
        width: 80%;
    }
    
    .artist-page {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1686930140/Desktop_Artist_BG-50_o6x1ba.jpg");
    }

    .artist-main-image {
        width: 80%;
    }

    .artist-blurb {
        padding-right: 20%;
    }

    .social {
        word-break: break-all;
    }

    .info-container {
        padding: 1rem;
    }
}

/* TABLET-L */
@media screen and (min-width: 769px) and (max-width: 899px){
    .line-div {
        border-bottom: 2px solid white;
        width: 65%;
    }

    .artist-page {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1686930140/Desktop_Artist_BG-50_o6x1ba.jpg");
    }

    .artist-main-image {
        width: 90%;
    }

    .artist-blurb {
        padding-right: 20%;
    }

    .info-container {
        padding: 1rem;
    }

    .artist-wrapper {
        max-width: 50%;
    }

    .artist-info-container{
        width: 90%;
    }
}

/* LAPTOP - S */
@media screen and (min-width: 900px) and (max-width: 1023px){
    .line-div {
        border-bottom: 2px solid white;
        width: 65%;
    }

    .artist-page {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1686930140/Desktop_Artist_BG-50_o6x1ba.jpg");
    }

    .artist-main-image {
        width: 90%;
    }

    .artist-blurb {
        padding-right: 20%;
    }

    .info-container {
        padding: 1rem;
    }

    .artist-wrapper {
        max-width: 40%;
    }

    .artist-info-container{
        width: 90%;
    }
}

/* LAPTOP */
@media screen and (min-width: 1024px) {
    .line-div {
        border-bottom: 3px solid white;
        width: 65%;
    }

    .artist-wrapper {
        max-width: 30%;
    }

    .artist-page {
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1686930140/Desktop_Artist_BG-50_o6x1ba.jpg");
    }

    .artist-main-image {
        width: 100%;
    }

    .artist-blurb {
        padding-right: 15%;
    }

    .info-container {
        padding: 1rem;
    }
}

/* DESKTOP */
@media screen and (min-width: 1441px){
    .line-div {
        border-bottom: 3px solid white;
        width: 65%;
    }

    .artist-wrapper {
        max-width: 30%;
    }
}