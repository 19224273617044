.event-space-name, .event-space-nickname, .event-space-content-container, .upgrade-blurb{
    color: #1f78bd;
}

.event-space-image{
    margin-bottom: 10px;
    margin-top: 10px;
}

.event-space-content-title{
    border-bottom: 2px solid #1f78bd;
}

/* MOBILE-S */
@media screen and (max-width: 375px){
    .event-space-container {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .event-space-inner-container{
        width: 90%;
    }

    .event-space-name, .event-space-nickname{
        font-size: 6vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 4vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    
    }
}

/* MOBILE-L */
@media screen and (min-width: 376px) and (max-width: 599px){
    .event-space-container {
        width: 90%;
    }


    .event-space-container {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    
    .event-space-inner-container{
        width: 90%;
    }

    .event-space-name, .event-space-nickname{
        font-size: 5vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 4vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    }
}

/* TABLET-S */
@media screen and (min-width: 600px) and (max-width: 699px){
    .event-space-container{
        width: 75%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .event-space-inner-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .event-space-name, .event-space-nickname{
        font-size: 4vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 3vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    }
}

/* TABLET-L */
@media screen and (min-width: 700px) and (max-width: 899px){
    .event-space-container{
        width: 60%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .event-space-inner-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .event-space-name, .event-space-nickname{
        font-size: 3vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 2.5vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    }
}

/* LAPTOP */
@media screen and (min-width: 900px) and (max-width: 1199px){
    .event-space-container{
        width: 60%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .event-space-inner-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .event-space-name, .event-space-nickname{
        font-size: 3vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 2vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    }
}

/* DESKTOP */
@media screen and (min-width: 1200px) and (max-width: 1399px){
    .event-space-container{
        width: 60%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .event-space-inner-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .event-space-name, .event-space-nickname{
        font-size: 3vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 2vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    }
}

/* WIDESCREEN */
@media screen and (min-width: 1400px){
    .event-space-container{
        width: 50%;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .event-space-inner-container{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .event-space-name, .event-space-nickname{
        font-size: 2vw;
    }

    .event-space-content-title, .event-space-content-item, .upgrade-header{
        font-size: 1.5vw;
    }

    .event-space-content-title{
        padding-bottom: 15px;
        margin-bottom: 15px;

    }

    .event-space-content-item{
        margin-bottom: 10px;
    }

    .event-space-content{
        margin-bottom: 20px;
    }

    .event-space-image{
        width: 100%;
        height: auto;
    }

    .event-space-content-title{
        border-width: 3px;
    }
}