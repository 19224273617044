.story-container {
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    background: url('https://res.cloudinary.com/dyjzfdguj/image/upload/v1734543975/story/Mobile_Story_bg_kbv8m4.webp');
}

.story-main-background {
    height: 100%;
    min-width: 100%;
    width: auto;
    position: fixed;
}

.story-inner-container {
    height: 80%;
    width: 100%;
    margin-top: 20%;
    display: flex;
}

.story-post {
    width: 100%;
    height: 150vh;
}

.icons-container {
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers icons horizontally */
    height: 100%; /* Stretch icons container for full scrolling length */
    justify-content: space-around; /* Evenly space icons along the container */
    width: 10vw;
    position: fixed;

}

.carousel-arrow-story {
    text-align: center;
    margin: 8% 0;
    z-index: 14;
    min-height: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    min-width: 10%;
}

.icon {
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.icon:hover {
    opacity: 1;
}

.post1 {
    background-color: red;
}

.post2 {
    background-color: yellow;
}

.post3 {
    background-color: green;
}

.post4 {
    background-color: blue;
}

.post5 {
    background-color: purple;
}

.post6 {
    background-color: orange;
}

.not-active {
    opacity: 0.5;
}

.progress-bar {
    width: 1%;
    background-color: transparent;
    transition: height 0.05s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
}

.post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 90vw;
    color: white;
}

.icons-container, .post-container {
    z-index: 3;
}

.image-post{
    width: 100%;
}

.post-image-container{
    width: 100%;
}

.post-image {
    width: 100%;
}

.post-text {
    width: 100%;
    padding: 20px;
    font-size: 20px;
}

.image-title, .text-title {
    font-size: 30px;
    margin-bottom: 20px;
}

.image-date, .text-date {
    font-size: 12px;
    padding: 5px 0 0px 0;
}

.text-content, .image-content {
    font-size: 14px;
    padding: 20px 0 150px 0;
}

@media screen and (max-width: 475px){
    .post-container {
        width: 75vw;
    }
}

@media screen and (min-width: 476px){
    .story-inner-container {
        width: 390px;
    }

    .post-text {
        width: 100%;
        padding: 20px;
        font-size: 20px;
    }
    
    .image-title, .text-title {
        font-size: 30px;
    }
    
    .image-date, .text-date {
        font-size: 12px;
        padding: 5px 0 0px 0;
    }
    
    .text-content, .image-content {
        font-size: 12px;
        padding: 20px 0 150px 0;
    }
}

@media screen and (min-width: 768px){

    .carousel-arrow-story {
        min-width: 20%;
    }

    .story-inner-container {
        width: 550px;
    }

    .post-text {
        width: 100%;
        padding: 20px;
        font-size: 24px;
    }
    
    .image-title, .text-title {
        font-size: 32px;
    }
    
    .image-date, .text-date {
        font-size: 16px;
        padding: 5px 0 0px 0;
    }
    
    .text-content, .image-content {
        font-size: 16px;
        padding: 20px 0 150px 0;
    }

    .story-container{
        background: url('https://res.cloudinary.com/dyjzfdguj/image/upload/v1734543975/story/Tablet_Story_bg_adpqgb.webp');
        background-size: cover;
    }
}

@media screen and (min-width: 1024px){
    .story-container{
        background: url('https://res.cloudinary.com/dyjzfdguj/image/upload/v1734543975/story/Desktop_Story_bg_j7zwvg.webp');
        background-size: cover;
    }
}