@font-face {
    font-family: 'citrusGothic';
    src: url('../../public/CitrusGothic-Regular.ttf');
}

.loading-container{
    width: 100%;
    height: 100%;
    z-index: 30;
    position: fixed;
    background-color: #004c8f;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}

.enter-button{
    cursor: pointer;
    margin-top: 20px;
    font-family: citrusGothic;
}

.slider-container{
    height: auto;
}

.fish {
    position: absolute;
}

.fish1{
    left: 0px;
    top: 10px;
}

.fish2{
    right: 0px;
    bottom: 10px;
}

@media screen and (max-width: 479px){
    .fish{
        display: none;
    }
    .loading-container{
        background: url('../../public/images/bgMobile.webp');
        background-size: cover;
    }

    .enter-button{
        font-size: 2.5rem;
    }

    .slider-container{
        width: 100%;
    }
}

@media screen and (min-width: 480px) and (max-width: 599px){
    .fish{
        display: none;
    }

    .loading-container{
        background: url('../../public/images/bgMobile.webp');
        background-size: cover;
    }

    .enter-button{
        font-size: 3rem;
    }

    .slider-container{
        width: 80%;
    }
}

@media screen and (min-width: 600px) and (max-width: 767px){
    .fish{
        display: none;
    }

    .loading-container{
        background: url('../../public/images/bgTablet.webp');
        background-size: cover;
    }

    .enter-button{
        font-size: 3.5rem;
    }

    .slider-container{
        width: 60%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px){
    .loading-container{
        background: url('../../public/images/bgTablet.webp');
        background-size: cover;
    }

    .enter-button{
        font-size: 2.5rem;
    }

    .slider-container{
        width: 450px;
    }
}

@media screen and (min-width: 768px) and (max-width: 949px){
    .fish1, .fish2{
        width: 200px;
        height: auto;
    }
}

@media screen and (min-width: 950px) and (max-width: 1199px){
    .fish1, .fish2{
        width: 300px;
        height: auto;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1399px){
    .fish1, .fish2{
        width: 400px;
        height: auto;
    }
}

@media screen and (min-width: 1400px){
    .fish1, .fish2{
        width: 500px;
        height: auto;
    }
}

@media screen and (min-width: 1200px){
    .loading-container{
        background: url('../../public/images/bgDesktop.webp');
        background-size: cover;
    }

    .enter-button{
        font-size: 3rem;
    }

    .slider-container{
        width: 450px;
    }
}