@font-face {
    font-family: 'citrusGothic';
    src: url('../../public/CitrusGothic-Regular.ttf');
}

.events-container{
    color: #1f78bd;
}

.event-spaces-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.events-header{
    margin-bottom: .1rem;
}

.upgrade-header{
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
    color:#1f78bd;
}

.upgrade{
    margin-bottom: 20px;
}

.upgrade-blurb{
    text-align: center;
}

.events-container{
    width: 100vw;
    background-color: #ffffff;
}

.events-image-mobile{
    width: 100%;
    height: auto;
}

.events-image-tablet{
    width: 100%;
    height: auto;
}

.events-image-desktop{
    width: 100%;
    height: auto;
}

.events-header, .upgrades-header{
    font-family: citrusGothic;
    color: #1f78bd;
    text-align: center;
}

.events-blurb{
    color: #1f78bd;
    text-align: center;
}
/* MOBILE-S */
@media screen and (max-width: 375px){
    .events-header{
        font-size: 2rem;
    }

    .events-blurb{
        font-size: .8rem;
        padding-right: 10px;
        padding-left: 10px;
        margin-bottom: 1rem;
    }

    .wave-break{
        width: 90%;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 2rem;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .upgrade{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 60%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: .8rem;
        margin-bottom: 10px;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 80%;
    }

    .additional-info-header{
        font-size: 1.2rem;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: .8rem;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: .8rem;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: .8rem;
    }

    .events-image-tablet{
        display: none;
    }

    .events-image-desktop{
        display: none;
    }
}

/* MOBILE-L */
@media screen and (min-width: 376px) and (max-width: 599px){
    .events-header{
        font-size: 9vw;
    }

    .events-blurb{
        font-size: 3.5vw;
        margin-bottom: 1rem;
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .wave-break{
        width: 90%;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 10vw;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .upgrade{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 45%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: 1rem;
        margin-bottom: 10px;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 80%;
    }

    .additional-info-header{
        font-size: 1.7rem;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: 1.2rem;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: .8rem;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1rem;
    }

    .events-image-mobile {
        display: none;
    }

    .events-image-desktop{
        display: none;
    }
}

/* TABLET-S */
@media screen and (min-width: 600px) and (max-width: 699px){
    .events-header{
        font-size: 7vw;
    }

    .events-blurb{
        font-size: 2.5vw;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        margin-bottom: 1rem;
        width: 80%;
    }

    .wave-break{
        width: 70vw;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 8vw;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .upgrade{
        width: 40%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 60%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: 2.5vw;
        margin-bottom: 4rem;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 6vw;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 70%;
    }

    .additional-info-header{
        font-size: 3.8vw;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: 3.1vw;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: 2.55vw;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 2.55vw;
    }

    .events-image-mobile{
        display: none;
    }

    .events-image-desktop{
        display: none;
    }
}

/* TABLET-L */
@media screen and (min-width: 700px) and (max-width: 899px){
    .events-header{
        font-size: 5.5vw;
    }

    .events-blurb{
        font-size: 2.5vw;
        padding-right: 2.5rem;
        padding-left: 2.5rem;
        margin-bottom: 1rem;
        width: 75%;
    }

    .wave-break{
        width: 70vw;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 8vw;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 80%;
    }

    .upgrade{
        width: 50%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 60%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: 2.5vw;
        margin-bottom: 4rem;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 6vw;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 70%;
    }

    .additional-info-header{
        font-size: 3.8vw;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: 3.1vw;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: 2.55vw;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 2.55vw;
    }

    .events-image-tablet{
        display: none;
    }

    .events-image-mobile{
        display: none;
    }
}

/* LAPTOP */
@media screen and (min-width: 900px) and (max-width: 1199px){
    .events-header{
        font-size: 6vw;
    }

    .events-blurb{
        font-size: 2vw;
        padding-right: 10vw;
        padding-left: 10vw;
        margin-bottom: 1rem;
        width: 80%;
    }

    .wave-break{
        width: 70vw;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 6vw;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 80%;
    }

    .upgrade{
        width: 33%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 60%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: 1.3vw;
        margin-bottom: 4rem;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 6vw;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 70%;
    }

    .additional-info-header{
        font-size: 3vw;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: 2.3vw;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: 2vw;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 2vw;
    }

    .events-image-tablet{
        display: none;
    }

    .events-image-mobile{
        display: none;
    }
}

/* DESKTOP */
@media screen and (min-width: 1200px) and (max-width: 1399px){
    .events-header{
        font-size: 6vw;
    }

    .events-blurb{
        font-size: 1.7vw;
        padding-right: 10vw;
        padding-left: 10vw;
        margin-bottom: 1rem;
        width: 80%;
    }

    .wave-break{
        width: 70vw;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 6vw;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 80%;
    }

    .upgrade{
        width: 33%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 60%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: 1.3vw;
        margin-bottom: 4rem;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 6vw;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 70%;
    }

    .additional-info-header{
        font-size: 2.4vw;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: 2vw;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: 1.3vw;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.3vw;
    }

    .events-image-tablet{
        display: none;
    }

    .events-image-mobile{
        display: none;
    }
}

/* WIDESCREEN */
@media screen and (min-width: 1400px){
    .events-header{
        font-size: 6vw;
    }

    .events-blurb{
        font-size: 2vw;
        padding-right: 10vw;
        padding-left: 10vw;
        margin-bottom: 1rem;
        width: 80%;
    }

    .wave-break{
        width: 70vw;
        display: flex;
        justify-content: center;
    }

    .upgrades-header{
        font-size: 6vw;
        margin-top: 40px;
        margin-bottom: 1rem;
    }

    .upgrades-inner-container{
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 80%;
    }

    .upgrade{
        width: 33%;
        display: flex;
        flex-direction: column;
    }

    .upgrade-icon{
        width: 60%;
        height: auto;
    }

    .upgrade-info-container{
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .upgrade-blurb{
        font-size: 1.3vw;
        margin-bottom: 4rem;
    }

    .events-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 6vw;
        color: white;
        background-color: #1f78bd;
    }

    .additional-info-container{
        width: 70%;
    }

    .additional-info-header{
        font-size: 3vw;
        font-weight: bold;
        color: #1f78bd;
    }

    .info-header{
        font-size: 2.3vw;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .package-list{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 2rem;
    }

    .list-item{
        font-size: 2vw;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 50%;
    }

    .last-item{
        margin-right: 50%;
    }

    .rule{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 2vw;
    }

    .events-image-tablet{
        display: none;
    }

    .events-image-mobile{
        display: none;
    }
}