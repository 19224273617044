.events-footer-content{
    display: flex;
    font-family: citrusGothic;
}

.submit-button{
    background-color: #1f78bd;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    font-family: citrusGothic;
    letter-spacing: 1px;
}

.contact-container{
    margin-bottom: 40px;
    margin-top: 40px;
}

.additional-info-container{
    margin-top: 40px;
    margin-bottom: 40px;
}

.contact-header{
    width: 80%;
    color: #1f78bd;
    font-weight: bold;
}

.contact-form{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 80%;
    height: 250px;
}

.name-input, .email-input{
    width: 100%;
}

.message-input{
    top: 90px;
    left: 0;
    height: 100px;
    width: 100%;
}

.name-input{
    top: 0;
    left: 0;
}

.email-input{
    top: 45px;
    left: 0;
}

/* MOBILE-S */
@media screen and (max-width: 375px){
    .contact-container{
        width: 90%;
    }

    .contact-header{
        font-size: 1.7rem;
    }

    .message-input{
        width: 100%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}

/* MOBILE-L */
@media screen and (min-width: 376px) and (max-width: 499px){
    .contact-container{
        width: 90%;
    }

    .contact-header{
        font-size: 1.7rem;
    }

    .message-input{
        width: 100%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}

/* TABLET-S */
@media screen and (min-width: 500px) and (max-width: 699px){
    .contact-container{
        width: 90%;
    }

    .contact-header{
        font-size: 1.7rem;
    }

    .message-input, .name-input, .email-input{
        width: 70%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}

/* TABLET-L */
@media screen and (min-width: 700px) and (max-width: 899px){
    .contact-container{
        width: 90%;
    }

    .contact-header{
        font-size: 1.7rem;
    }

    .message-input, .name-input, .email-input{
        width: 70%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}

/* LAPTOP */
@media screen and (min-width: 900px) and (max-width: 1199px){
    .contact-container{
        width: 70%;
    }

    .contact-header{
        font-size: 1.7rem;
    }

    .message-input, .name-input, .email-input{
        width: 70%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}

/* DESKTOP */
@media screen and (min-width: 1200px) and (max-width: 1399px){
    .contact-container{
        width: 70%;
    }

    .contact-header{
        font-size: 2.4vw;
    }

    .message-input, .name-input, .email-input{
        width: 70%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}

/* WIDESCREEN */
@media screen and (min-width: 1400px){
    .contact-container{
        width: 70%;
    }

    .contact-header{
        font-size: 1.7rem;
    }

    .message-input, .name-input, .email-input{
        width: 70%;
    }

    .form-button-wrapper{
        position: absolute;
        bottom: 0;
    }
}