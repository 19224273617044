@font-face {
  font-family: montserrat;
  src: url('../../public/Montserrat-VariableFont_wght.ttf');
}

@font-face {
  font-family: montserratThinItalic;
  src: url('../../public/Montserrat-ThinItalic.ttf');
}

@font-face {
  font-family: montserratItalic;
  src: url('../../public/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
  font-family: montserratThin;
  src: url('../../public/Montserrat-Thin.ttf')
}

@font-face {
  font-family: montserratExtraLight;
  src: url('../../public/Montserrat-ExtraLight.ttf')
}

a { 
  color: white; 
  text-decoration: none; 
  cursor: pointer;
}

.carousel-arrow {
  text-align: center;
  margin: 8% 0;
  z-index: 3;
  min-height: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  min-width: 20%;
}

.bounce-right {
  animation: bounce-right 6s infinite;
}

.bounce-left {
  animation: bounce-left 6s infinite;
}

.artifact-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 10%;
  font-family: montserrat;
  position: relative;
}

.artifact-blurb {
  color: white;
  margin-top: 8%;
  width: 80%;
  margin-left: 5%;
  font-style: italic;
}

.last-artifact-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
  font-family: montserrat;
}

.info-wrapper {
  margin-top: 4%;
}

.artifact-name, .last-artifact-name {
  letter-spacing: 2px;
  color: white;
  overflow-wrap: break-word;
  max-width: 90%;
  font-style: bold;
}

.artifact-artist-name {
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  margin-top: 1%;
}

.last-name {
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  margin-top: 1%;
}

.artifact-artist-wrapper {
    width: 60%;
    position: absolute;
    margin-left: 5%;
  }

  .single-line-div {
    height: 1px;
    border-bottom: 3px solid white;
    margin-left: 5%;
    margin-top: 2%;
    width: 50%;
}

.last-line-div {
  border-bottom: 3px solid white;
  width: 50%;
  max-width: 250px;
  position: absolute;
  margin-top: 1%;
  margin-left: 5%;
}

a:link { text-decoration: none; }
  
a:visited { text-decoration: none; }

.artifact-artist-description {
  color: white;
  font-style: italic;
  margin-top: 10%;
}

.last-description {
  color: white;
  font-size: .8rem;
  font-style: italic;
  margin-top: 10%;
  letter-spacing: 4px;
  margin-left: 5%;
}

.inner-wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.artifact-info {
  font-size: 24px;
  color: white;
  background-color: #bcbcbc;
  border: 2px solid #a1a1a1;
  box-shadow: 1% 1% 1% rgb(0 0 0 / 50%);
  width: 20%;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

/* MOBILE-S */
@media screen and (max-width: 320px){
  .artifact-carousel .artifact-image {
    max-width: 85%;
  }

  .bounce-right {
    right: 0;
  }
  
  .bounce-left {
    left: 0;
  }
  
  .artifact-container {
      margin-bottom: 80%;
  }
  
  .artifact-blurb {
    font-size: .9rem;
    font-family: montserratExtraLight;
  }
  
  .info-wrapper {
    margin-left: 3%;
  }
  
  .artifact-name {
    font-size: 1.4em;
  }
  
  .last-artifact-name {
    font-size: 1.4em;
  }
  
  .artifact-artist-name {
    font-size: 1.1rem;
  }
  
  .last-name {
    font-size: 1.1rem;
  }
  
  .artifact-artist-description {
      font-size: .6rem;
      letter-spacing: 3px;
  }
}

/* MOBILE */
@media screen and (min-width: 321px) and (max-width: 375px){
  .artifact-carousel .artifact-image {
    max-width: 85%;
  
  }
  
  .bounce-right {
    right: 0;
  }
  
  .bounce-left {
    left: 0;
  }
  
  .artifact-container {
      margin-bottom: 70%;
  }
  
  .artifact-blurb {
    font-size: 1.1rem;
    font-family: montserrat;
  }
  
  .info-wrapper {
    margin-left: 3%;
  }
  
  .artifact-name {
    font-size: 1.7em;
  }
  
  .last-artifact-name {
    font-size: 1.7em;
  }
  
  .artifact-artist-name {
    font-size: 1.4rem;
  }
  
  .last-name {
    font-size: 1.4rem;
  }
  
  .artifact-artist-description {
      font-size: .8rem;
      letter-spacing: 4px;
  }
}

/* MOBILE-L */
@media screen and (min-width: 376px) and (max-width: 490px){
  .artifact-carousel .artifact-image {
    max-width: 85%;
  
  }
  
  .bounce-right {
    right: 0;
  }
  
  .bounce-left {
    left: 0;
  }
  
  .artifact-container {
      margin-bottom: 70%;
  }
  
  .artifact-blurb {
    font-size: 1.1rem;
    font-family: montserrat;
  }
  
  .info-wrapper {
    margin-left: 3%;
  }
  
  .artifact-name {
    font-size: 1.7em;
  }
  
  .last-artifact-name {
    font-size: 1.7em;
  }
  
  .artifact-artist-name {
    font-size: 1.4rem;
  }
  
  .last-name {
    font-size: 1.4rem;
  }
  
  .artifact-artist-description {
      font-size: .8rem;
      letter-spacing: 4px;
  }
}

/* TABLET */
@media screen and (min-width: 491px) and (max-width: 768px){
  .artifact-carousel .artifact-image {
    max-width: 60%;
  }
  
  .bounce-right {
    right: 0;
  }
  
  .bounce-left {
    left: 0;
  }
  
  .artifact-container {
      margin-bottom: 50%;
  }
  
  .artifact-blurb {
    font-size: 1.1rem;
    font-family: montserrat;
    padding-right: 20%;
  }
  
  .info-wrapper {
    margin-left: 16%;
  }
  
  .artifact-name {
    font-size: 1.7em;
  }
  
  .last-artifact-name {
    font-size: 1.7em;
  }
  
  .artifact-artist-name {
    font-size: 1.4rem;
  }
  
  .last-name {
    font-size: 1.4rem;
  }
  
  .artifact-artist-description {
      font-size: .8rem;
      letter-spacing: 4px;
  }
}

/* LAPTOP */
@media screen and (min-width: 769px) and (max-width: 1024px){
  .artifact-carousel .artifact-image {
    max-width: 85%;
  
  }
  
  .bounce-right {
    right: 0;
  }
  
  .bounce-left {
    left: 0;
  }
  
  .artifact-container {
      margin-bottom: 100%;
  }
  
    .artifact-container {
        max-width: 45%;
        margin-bottom: 32%;
    }

    .artifact-carousel .artifact-image {
      max-width: 85%;
    }
    

    .carousel-wrapper {
        max-height: 100%;
    }
    
    .bounce-right {
      right: -5%;
    }
    
    .bounce-left {
      left: -5%;
    }

    .artifact-blurb {
      font-size: 1.1rem;
      font-family: montserrat;
    }
    
    .info-wrapper {
      margin-left: 3%;
    }
    
    .artifact-name {
      font-size: 1.7em;
    }
    
    .last-artifact-name {
      font-size: 1.7em;
    }
    
    .artifact-artist-name {
      font-size: 1.4rem;
    }
    
    .last-name {
      font-size: 1.4rem;
    }
    
    .artifact-artist-description {
        font-size: .8rem;
        letter-spacing: 4px;
    }
}

/* DESKTOP */
@media screen and (min-width: 1025px){
  .artifact-carousel .artifact-image {
    max-width: 75%;
  }
  
  .bounce-right {
    right: 0;
  }
  
  .bounce-left {
    left: 0;
  }
  
  .artifact-container {
      margin-bottom: 100%;
  }
  
    .artifact-container {
        max-width: 35%;
        margin-bottom: 45%;
    }

    .carousel-wrapper {
        max-height: 100%;
    }
    
    .bounce-right {
      right: -5%;
    }
    
    .bounce-left {
      left: -5%;
    }
    
    .artifact-blurb {
      font-size: 1.1rem;
      font-family: montserrat;
    }
    
    .info-wrapper {
      margin-left: 8%;
    }
    
    .artifact-name {
      font-size: 1.7em;
    }
    
    .last-artifact-name {
      font-size: 1.7em;
    }
    
    .artifact-artist-name {
      font-size: 1.4rem;
    }
    
    .last-name {
      font-size: 1.4rem;
    }
    
    .artifact-artist-description {
        font-size: .8rem;
        letter-spacing: 4px;
    }
}

@keyframes bounce-right {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(-10px);
  }
}

@keyframes bounce-left {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(10px);
  }
}
  @keyframes carousel {
    0% {
      transform: translateX(0);
    }
    20% {
        transform: translateX(0)
    }
    33% {
      transform: translateX(-100%);
    }
    53% {
      transform: translateX(-100%);
    }
    66% {
      transform: translateX(-200%);
    }
    76% {
      transform: translateX(-200%);
    }
    86% {
        transform: translateX(-100%);
    }
    94% {
        transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }