.footer-container {
    border-top: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
}

.footer-artists {
    display: flex;
    justify-content: space-around;
    font-size: 1.3rem;
    color: white;
}

.footer-artist-container {
    padding: 10px;
}

.footer-artist-description {
    font-style: italic;
    letter-spacing: 4px;
    margin-bottom: 10px;
    font-size: 1rem;
}

.footer-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: white;
}