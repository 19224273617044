* {
    margin: 0 auto;
    box-sizing:border-box
}

body {
    background-color: #004c8f;
}

.home-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.gallery-container{
    background-position: center;
    min-height: 15000px;
}

@media screen and (max-width: 499px){
    .gallery-container {
        padding-top: 1000px;
        background-size: 100% 100.0001%;
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1725377519/bg_mobile_dpf8ow.jpg");
    }
}

@media screen and (min-width: 500px) and (max-width: 599px){
    .gallery-container {
        padding-top: 1400px;
        background-size: auto 100.0001%;
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1725377644/bg_desktop_stf6cv.jpg");
    }
}

@media screen and (min-width: 600px){
    .gallery-container {
        padding-top: 1000px;
        background-position: center;
        background-image: url("https://res.cloudinary.com/dyjzfdguj/image/upload/v1725377644/bg_desktop_stf6cv.jpg");
        background-size: auto 100.0001%;
        /* This is 100.0001% because for some reason chrome does not render the background at 100% at very specific widths.  This is a work around */
    }
}

@media screen and (min-width: 1400px) and (max-width: 1999px){
    .gallery-container{
        padding-top: 1500px;
    }
}

@media screen and (min-width: 2000px) and (max-width: 2999px){
    .gallery-container{
        padding-top: 2000px;
    }
}

@media screen and (min-width: 3000px){
    .gallery-container{
        padding-top: 2500px;
    }
}